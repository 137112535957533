@use 'variable' as *;
@use 'mixin';

.menu-icon {
    display: none;
}

.page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .logo {
        height: 3rem;
    }
}

.search-form {
    .search-input {
        appearance: none;
        border: 1px solid $border;
        border-radius: 0;
        border-right: 0;
        color: $text;
        margin: 0;
        padding: 9px 5px 9px 10px;
        transition: all 0.3s linear;
        width: 165px;
    }
}

#search-button {
    appearance: none;
    background: $white;
    border: 1px solid $border;
    border-left: 0;
    cursor: pointer;
    padding-top: 5px;
    width: 35px;

    svg {
        fill: $border;
    }

    &:hover {
        background: $border;
    }

    &:hover svg {
        fill: $white;
    }
}

nav {
    border-bottom: 0.2rem solid $border;
    border-top: 0.2rem solid $border;

    #page-navigation {
        display: inline-block;
        margin: 0;

        .active {
            box-shadow: 0 0.2rem 0 0 $hover-color;
        }

        li {
            display: inline-block;
            line-height: 3;
            margin: 0 1rem;

            a {
                @include mixin.font-set(1rem, $title, bold);

                &:hover {
                    color: $hover-color;
                }
            }
        }
    }
}

ul#page-navigation > li.active a {
    color: $hover-color;
}

/*
*
* media-max: 992px
*
*/
@media (max-width: 992px) {
    .search-form {
        display: none;
    }

    nav {
        border: 0;
        display: none;
        padding: 0;

        #page-navigation {
            display: block;

            .active {
                box-shadow: -0.2rem 0 0 0 $hover-color;
            }

            li {
                display: block;
                line-height: 3;
                margin-right: 1rem;
                padding: 0 0 0 1rem;

                a {
                    @include mixin.font-set(1rem, $title, bold);
                }
            }
        }
    }

    #openMenu.menu-icon {
        display: block;

        &.hide-icon {
            display: none;
        }
    }

    #closeMenu.menu-icon {
        display: none;

        &.show-icon {
            display: block;
        }
    }
}
