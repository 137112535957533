@use 'variable' as *;

.validated-user-order-form {
    label {
        min-width: 10rem;
    }

    h2,
    h3,
    h4 {
        color: $primary;
        font-weight: 400;
        margin-top: 0.5rem;
    }
}
