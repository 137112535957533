@use 'variable' as *;
@use 'mixin';

/*
*
* aldryn-newsblog-pagination
*
*/
.aldryn-newsblog-pagination {
    margin-top: 1rem;

    ul {
        li {
            display: inline-block;
            margin: 0 2px;

            a,
            span {
                @include mixin.font-set(1rem, $text, 300);

                border: 1px solid $text;
                display: inline-block;
                line-height: 1.55rem;
                min-width: 1.55rem;
                padding: 0.2rem 0.4rem;
            }

            a span {
                border: 1px solid transparent;
                line-height: 1;
            }

            a:hover {
                border: 1px solid $hover-color;
                color: $hover-color;
            }
        }

        li.active {
            span {
                border: 1px solid transparent;
            }
        }
    }
}
