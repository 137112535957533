@use 'variable' as *;

ul.messagelist {
    background-color: $hover-color;
    font-size: 160%;
    list-style-type: none;
    margin: 0 0 1rem;
    padding: 0;

    li {
        list-style-image: none;
        margin: 0;
        padding: 0.6rem 1.6rem;

        &.info {
            background-color: $primary;
        }

        &.warning {
            background-color: $warning;
        }

        &.success {
            background-color: $success;
        }

        &.error {
            background-color: $hover-color;
            color: $white;
        }
    }
}

@media (max-width: 574px) {
    ul.messagelist {
        font-size: 110%;

        li {
            padding: 0.2rem 0.8rem;
        }
    }
}
