@use 'variable' as *;
@use 'mixin';

.aldryn .aldryn-newsblog-pager ul.pager li {
    float: none;
    margin: 0;
}

.aldryn-newsblog-list {
    article {
        border-bottom: 0.1rem solid $border;
        padding-bottom: 1.5rem;

        &:last-of-type {
            border: 0;
        }

        h2:not(:first-child) {
            margin-top: 0.5rem;
        }
    }
}

.container.page-with-newsblog {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    aside {
        margin-left: 4vw;
        margin-right: 0;
        min-width: 18rem;
        width: 18rem;

        header {
            h1,
            h2,
            h3 {
                @include mixin.font-set(1.8rem, $hover-color, 300);

                padding: 0;
            }
        }
    }

    .newsblog {
        border: 0.2rem solid $border;
        padding: 0 2rem 2rem;

        .aldryn-newsblog {
            border-bottom: 0.1rem solid $border;
            margin-bottom: 3rem;
            padding-bottom: 0.45rem;

            h2 {
                a {
                    color: $hover-color;
                    text-decoration: none;
                }
            }

            h2:not(:first-child) {
                margin-top: 0;
            }

            img {
                margin-top: 2rem;
                max-width: 100%;
            }
        }
    }

    .markdown-text * {
        font-size: 1rem;
    }

    .alert-success {
        @include mixin.font-set(1.8rem, $hover-color, 300);

        line-height: 1.33rem;
        margin-bottom: 0.66rem;
        padding-bottom: 1rem;
    }
}

.is-homepage {
    .container.page-with-newsblog {
        justify-content: flex-end;

        aside {
            width: 25%;
        }
    }
}

.col-md-17 {
    width: 100%;
}

.aldryn-newsblog-article {
    .lead {
        font-size: 1rem;
    }
}

/*
*
* media-max: 73rem
*
*/
@media (max-width: 73rem) {
    .container.page-with-newsblog {
        flex-direction: column;

        aside {
            margin-left: 0;
            width: 100%;
        }
    }

    .is-homepage {
        .container.page-with-newsblog {
            aside {
                width: 100%;
            }
        }
    }
}
