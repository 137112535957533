@use 'variable' as *;
@use 'mixin';

html {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
}

body {
    color: $text;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-main {
    flex: 1;
}

//Title h 1-6 size
$min-width: 19rem;
$max-width: 48rem;

h1 {
    @include mixin.fluid-type($min-width, $max-width, 1.8rem, 2.5rem);

    line-height: 1.2;
}

h2 {
    @include mixin.fluid-type($min-width, $max-width, 1.3rem, 2rem);

    line-height: 1.2;
}

h3 {
    @include mixin.fluid-type($min-width, $max-width, 1.2rem, 1.75rem);

    line-height: 1.2;
}

h4 {
    @include mixin.fluid-type($min-width, $max-width, 1rem, 1.5rem);

    line-height: 1.2;
}

h5 {
    color: $title;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
}

h6 {
    color: $title;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
}

a {
    @include mixin.linx($hover-color, $primary, $primary);

    color: $primary;
    text-align: center;
    text-decoration: underline;

    &:hover {
        color: $hover-color;
    }
}

p {
    @include mixin.font-set(1rem, $text, 400);

    line-height: 1.2;
    margin-bottom: 0.66rem;
}

ul {
    margin: 0 0 0 1.5rem;

    li {
        @include mixin.font-set(1rem, $text, 400);

        line-height: 1.2;
        list-style-image: url('../img/arrow.svg');
        margin-bottom: 0.66rem;

        p {
            margin-bottom: 1rem;
        }
    }
}

strong {
    font-weight: bold;
}

pre {
    @include mixin.font-set(1rem, $text, 400);
}

code {
    @include mixin.font-set(1rem, $text, 400);

    background: $white;
    border-radius: 1px;
    color: $hover-color;
    display: block;
    margin-top: 0.3rem;
    padding: 0.3rem;
}

table tbody {
    font-size: 0.9em;
    line-height: 1.2;
}

body.show-body {
    background: $bg;
}

#pageContent.hide-content {
    display: none;
}

body.show-body .content-main,
body.show-body footer {
    display: none;
}

body.show-body nav {
    display: block;
}

.language-chooser {
    float: right;

    ul {
        margin: 0;
    }

    li {
        list-style-image: none;
        margin: 0 0 0 1rem;
    }
}

.lang.active {
    a {
        color: $text;
        text-decoration: none;
    }
}

.margin-0 * {
    margin: 0;
}

.padding-0 * {
    padding: 0;
}

.btn {
    color: $white;
    text-decoration: none;

    &:visited {
        color: $white;
    }
}

.btn-primary {
    background-color: $primary;
    border-color: $primary;

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary, 10%);
        border-color: darken($primary, 10%);
    }
}

.djangocms-oidc-signup {
    background: url('../img/login_with_mojeid_big_gray.png') no-repeat 100% -32px;
    border: 1px solid $white;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: Helvetica, sans-serif;
    font-size: 15px;
    font-weight: bold;
    height: 32px;
    line-height: 2em;
    margin: 0;
    padding: 1px 40px 0 6px;
    text-align: center;
    text-decoration: none;
    text-shadow: $shadow 0 -1px 0;
    vertical-align: top;

    &:hover,
    &:focus,
    &:active,
    &:visited {
        background: url('../img/login_with_mojeid_big_gray.png') no-repeat 100% -32px;
        border-color: $white;
        box-shadow: none;
        color: $white;
        outline: none;
        text-decoration: none;
    }
}

.btn-primary.mojeid-login:not(:disabled):not(.disabled):active {
    background: url('../img/login_with_mojeid_big_gray.png') no-repeat 100% -32px;
    border-color: $white;
    box-shadow: none;
    outline: none;
}
