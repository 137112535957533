@use 'variable' as *;

// /* FAQ dropdown */
.collapse-box {
    background: $bg;
    padding: 2rem 3rem;

    h5 {
        margin-top: 2rem;
    }

    [data-bs-toggle='collapse'] {
        border-top: 1px solid $border;
        cursor: pointer;
        padding: 1rem 1px;
        position: relative;

        a {
            color: $text;
            display: block;
            text-decoration: none;
        }

        > * {
            margin: 0;
            padding-right: 30px;
            position: relative;
        }

        &::after {
            content: url('../img/icon-plus.svg');
            display: block;
            height: 24px;
            opacity: 1;
            position: absolute;
            right: 0;
            top: 1rem;
            transform: rotate(45deg);
            transition: all 0.2s ease-in-out;
            width: 24px;
        }
    }

    [data-bs-toggle='collapse'][aria-expanded='true'] {
        &::after {
            opacity: 0.5;
            transform: rotate(90deg);
        }
    }

    > div:first-of-type {
        border-top: 0;
    }

    div[role='tabpanel'] {
        &.collapse {
            margin-bottom: 3rem;
        }
    }
}
