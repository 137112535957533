$bg: #f4f4f4;
$black: #000;
$border: #dadada;
$hover-color: #e12637;
$primary: #1ba5cf;
$shadow: #777;
$success: #32c518;
$table-bad: #f00;
$table-bg2: #f611f3;
$table-bg3: #fbfd53;
$table-bg: #f8fff8;
$table-header-text: #274221;
$table-header2: #4d953d;
$table-header: #d9ebd5;
$table-warning: #ffb200;
$text: #656565;
$title: #4c4c4c;
$warning: #f6d128;
$white: #fff;
