@use 'variable' as *;

footer {
    border-top: 0.2rem solid $border;
    margin-bottom: 1.5rem;
    margin-top: 0;
    padding-top: 1rem;

    @media (min-width: 768px) {
        margin-bottom: 3rem;
        margin-top: 1.5rem;
    }

    > section {
        align-items: baseline;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 992px) {
            flex-direction: row;
        }
    }
}
