@use 'variable' as *;

tbody tr th {
    background-color: $border;
}

.table-responsive > .table-bordered {
    border: 3px solid $title;
}

.table {
    background-color: $table-bg;

    .table-light {
        background-color: $white;
        border: 1px solid $border;
    }

    .b {
        background-color: $border;
    }

    .bad,
    .c {
        background-color: $table-bad;
    }

    .warning,
    .d {
        background-color: $table-warning;
    }

    .e {
        background-color: $table-bg;
    }

    .g {
        background-color: $table-bg2;
    }

    .h {
        background-color: $table-bg3;
    }

    .b,
    .c,
    .d,
    .g,
    .h {
        color: $black;
        font-weight: bold;
    }

    .thead-dark th {
        background-color: $table-header2;
        color: $white;
        vertical-align: baseline;
    }

    &.table-bordered td,
    &.table-bordered th {
        border: 0;
        border-bottom: 1px solid $border;
        border-left: 1px dotted $border;
    }

    &.owasp {
        background-color: $white;

        th {
            color: $black;
            text-align: center;
        }

        &.table-bordered td,
        &.table-bordered th {
            border: 2px solid $title;
        }

        .thead-dark th {
            background-color: $table-header;
            color: $table-header-text;
        }
    }
}
