.djangocms-oidc {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;

    > div {
        margin-top: 1rem;
        width: 100%;
    }
}

.oidc-form-item {
    align-items: baseline;
    display: flex;
    margin-bottom: 0.5rem;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    > * {
        margin: 0;
        min-width: 10.2rem;
        padding: 0;
    }
}

.djangocms-oidc-verified_as {
    margin-left: 0.4rem;
}

.djangocms-oidc-dismiss.mojeid {
    margin: 0 1rem;
}
